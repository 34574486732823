import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";

export interface ValueAnalysisRequest {
    fullName: string,
    email: string,
    companyName: string,
    workPhone?: number,
    jobTitle?: string,
    numberOfEmployees?: string,
    projectDescription: string,
    mutualNDA?: boolean,
    token: string,
}
class ValueAnalysisService extends ApiBaseService {
    public static async submitForm(request: ValueAnalysisRequest): Promise<IResponse<any>> {
        const response = await this.securedPost<any>(`value-analysis-requests`, { data: request });
        return response;
    }
}

export default ValueAnalysisService;