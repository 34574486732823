import React, { useRef, useState } from "react";
import * as styles from "./ValueAnalysisPage.module.scss";
import EventTrackerService from "../../services/EventTrackerService";
import { useForm } from "react-hook-form";
import { graphql, navigate } from "gatsby";
import ValueAnalysisService, { ValueAnalysisRequest } from "../../services/formRequestServices/ValueAnalysisService";
import Captcha, { ICaptchaHandle } from "../../components/Shared/Captcha";
import Spinner from "../../components/Shared/Spinner";
import { IResponse } from "../../services/apiServices/ApiBaseService";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

interface ValueAnalysisProps {
    data: GatsbyTypes.ValueAnalysisQuery;
    location: Location;
}

const ValueAnalysisPageTemplate = (props: ValueAnalysisProps) => {
    const [spinner, setSpinner] = useState<boolean>(false);
    const [showerror, setError] = useState<IResponse<any> | null>(null);
    const [formData, setFormData] = useState<{ [name: string]: string | any }>({
        mutualNDA: false,
    });
    const captchaRef = useRef<ICaptchaHandle>(null);
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
        clearErrors,
    } = useForm({ reValidateMode: "onBlur" });

    // page data
    const { strapiValueAnalysisPage } = props.data;
    const { hero, contactForm, contactInfo } = strapiValueAnalysisPage as GatsbyTypes.StrapiValueAnalysisPage;

    function handleValueChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (showerror) setError(null);
        clearErrors(event.target.name);
        if (event.target.name !== "mutualNDA") {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        } else {
            setFormData({ ...formData, [event.target.name]: !formData[event.target.name] });
        }
    }

    async function resetForm(): Promise<void> {
        setFormData({ mutalNDA: false });
        reset({});
        captchaRef?.current?.reset();
    }

    async function onSubmit(): Promise<void> {
        setSpinner(true);
        EventTrackerService.formSubmit("Value analysis form", true);

        try {
            const response = await ValueAnalysisService.submitForm(formData as unknown as ValueAnalysisRequest);
            if (response.httpStatusCode !== 200) {
                setError(response);
                setSpinner(false);
                return console.error(response);
            }
            await resetForm();
            setSpinner(false);
            navigate("/thank-you-contact");
        } catch (error) {
            setError({
                success: false,
                message: "We have an unexpected error",
                httpStatusCode: 500,
                response: "",
            });
            setSpinner(false);
            return console.error(error);
        }
    }

    return (
        <>
            <section key={"hero"} className={styles.heroWrapper}>
                <div className="mainBox">
                    <h1 className="font-serif">{hero.heading}</h1>
                </div>
            </section>

            <div className={styles.contactWrapper}>
                <div className={`${styles.inner} mainBox`}>
                    <div className={styles.textBlock}>
                        <h2>{contactInfo.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: contactInfo.paragraphHTML || contactInfo.paragraph }} />
                    </div>

                    <div className={styles.formBlock}>
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <>
                                {showerror && (
                                    <div>
                                        <p role="alert">Sorry, we have a problem:</p>
                                        <p role="alert">
                                            {showerror.response.name}, {showerror.response.message}. Check your data and try again{" "}
                                        </p>
                                    </div>
                                )}
                                <form name="valueAnalysis" onSubmit={handleSubmit(onSubmit)} noValidate>
                                    <div>
                                        <label htmlFor="fullName">{contactForm.fullName.label} *</label>
                                        <input
                                            className="formControl isOutline"
                                            {...register("fullName", {
                                                required: { value: true, message: "This field is required" },
                                            })}
                                            placeholder={contactForm.fullName.placeholder || ""}
                                            type="text"
                                            id="fullName"
                                            name="fullName"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.fullName ?? ""}
                                            aria-invalid={errors.fullName ? "true" : "false"}
                                        />
                                        {errors.fullName?.type === "required" && <p role="alert">{(errors.fullName.message as string) ?? ""}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="email">{contactForm.email.label} *</label>
                                        <input
                                            {...register("email", {
                                                required: { value: true, message: "This field is required" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email format",
                                                },
                                            })}
                                            className="formControl isOutline"
                                            placeholder={contactForm.email.placeholder || ""}
                                            type="email"
                                            id="email"
                                            name="email"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.email ?? ""}
                                            aria-invalid={errors.email ? "true" : "false"}
                                        />
                                        {(errors.email?.type === "pattern" || errors.email?.type === "required") && (
                                            <p role="alert">{(errors.email?.message as string) ?? ""}</p>
                                        )}
                                    </div>
                                    <div>
                                        {/* 
                                    regex matches
                                    xxx-xxx-xxxx
                                    (xxx) xxx-xxxx
                                    xxx xxx xxxx
                                    xxx.xxx.xxxx
                                    +xx (xxx) xxx-xxxx
                                    */}
                                        <label htmlFor="workPhone">{contactForm.workPhone.label}</label>
                                        <input
                                            {...register("workPhone", {
                                                required: { value: false, message: "" },
                                                pattern: {
                                                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                                    message: "Invalid phone number",
                                                },
                                            })}
                                            className="formControl isOutline"
                                            placeholder={contactForm.workPhone.placeholder || ""}
                                            type="tel"
                                            id="workPhone"
                                            name="workPhone"
                                            maxLength={13}
                                            onChange={handleValueChange}
                                            value={formData.workPhone ?? ""}
                                            aria-invalid={errors.workPhone ? "true" : "false"}
                                            onKeyPress={event => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.workPhone?.message && <p role="alert">{(errors.workPhone.message as string) ?? ""}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="companyName">{contactForm.companyName.label} *</label>
                                        <input
                                            {...register("companyName", { required: { value: true, message: "This field is required" } })}
                                            className="formControl isOutline"
                                            placeholder={contactForm.companyName.placeholder || ""}
                                            type="text"
                                            id="companyName"
                                            name="companyName"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.companyName ?? ""}
                                            aria-invalid={errors.companyName ? "true" : "false"}
                                        />
                                        {errors.companyName?.type === "required" && <p role="alert">{(errors.companyName.message as string) ?? ""}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="jobTitle">{contactForm.jobTitle.label}</label>
                                        <input
                                            {...register("jobTitle", { required: { value: false, message: "" } })}
                                            className="formControl isOutline"
                                            placeholder={contactForm.jobTitle.placeholder || ""}
                                            type="text"
                                            id="jobTitle"
                                            name="jobTitle"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.jobTitle ?? ""}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="numberOfEmployees">{contactForm.numberOfEmployees.label}</label>
                                        <input
                                            {...register("numberOfEmployees", { required: { value: false, message: "" } })}
                                            className="formControl isOutline"
                                            placeholder={contactForm.numberOfEmployees.placeholder || ""}
                                            type="text"
                                            id="numberOfEmployees"
                                            name="numberOfEmployees"
                                            maxLength={6}
                                            onChange={handleValueChange}
                                            value={formData.name}
                                            aria-invalid={errors.numberOfEmployees ? "true" : "false"}
                                            onKeyPress={event => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="projectDescription">{contactForm.projectDescription.label} *</label>
                                        <textarea
                                            {...register("projectDescription", { required: { value: true, message: "This field is required" } })}
                                            className="formControl isOutline"
                                            rows={4}
                                            placeholder={contactForm.projectDescription.placeholder || ""}
                                            id="projectDescription"
                                            name="projectDescription"
                                            maxLength={1000}
                                            onChange={handleValueChange}
                                            value={formData.projectDescription ?? ""}
                                            aria-invalid={errors.projectDescription ? "true" : "false"}
                                        />
                                        {errors.projectDescription?.type === "required" && (
                                            <p role="alert">{(errors.projectDescription.message as string) ?? ""}</p>
                                        )}
                                    </div>
                                    <div className={styles.ndaWrapper}>
                                        <input type="checkbox" value={formData.mutualNDA} id="mutualNDA" name="mutualNDA" onChange={handleValueChange} />
                                        <label htmlFor="mutualNDA">{contactForm.nda.label}</label>
                                    </div>
                                    <Captcha onVerified={token => setFormData({ ...formData, token })} ref={captchaRef} />
                                    <div className={styles.buttonWrapper}>
                                        <button className="button" type="submit" disabled={!formData?.token}>
                                            {contactForm.formActions.submitButton.text}
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ValueAnalysisPageTemplate;

export const query = graphql`
    query ValueAnalysis {
        strapiValueAnalysisPage {
            id
            nav
            contactInfo {
                paragraphHTML
                paragraph
                title
            }
            contactForm {
                companyName {
                    placeholder
                    label
                }
                email {
                    placeholder
                    label
                }
                formActions {
                    submitButton {
                        link
                        text
                    }
                }
                fullName {
                    label
                    placeholder
                }
                jobTitle {
                    placeholder
                    label
                }
                nda {
                    placeholder
                    label
                }
                numberOfEmployees {
                    placeholder
                    label
                }
                projectDescription {
                    label
                    placeholder
                }
                workPhone {
                    label
                    placeholder
                }
            }
            hero {
                heading
                id
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id="site-title">{seo.title}</title>
            <meta id="desc" name="description" content={seo.description} />
            <meta id="tw-card" name="twitter:card" content={seo.twitter.card} />
            <meta id="tw-site" name="twitter:site" content={seo.twitter.site} />
            <meta id="tw-creator" name="twitter:creator" content={seo.twitter.creator} />
            <meta id="tw-title" name="twitter:title" content={seo.twitter.title} />
            <meta id="tw-desc" name="twitter:description" content={seo.twitter.description} />
            <meta id="tw-img" name="twitter:image" content={seo.twitter.image.url} />
            <meta id="fb-type" property="og:type" content={seo.facebook.type} />
            <meta id="fb-title" property="og:title" content={seo.facebook.title} />
            <meta id="fb-desc" property="og:description" content={seo.facebook.description} />
            <meta id="fb-url" property="og:url" content={seo.facebook.url} />
            <meta id="fb-img" property="og:image" content={seo.facebook.image.url} />
        </SiteMetadataHead>
    );
}
